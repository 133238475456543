import LayoutSection from "../../../components/base/layout/section/layout-section.component";
import {Color} from "../../../enums/color.enum";
import "./contact.style.scss"
import LayoutGrid from "../../../components/base/layout/grid/layout-grid.component";

const ContactPage = () => {

    return (
        <div className="contact">
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Kontakt</h1>
                <h5>
                    Hast du noch weitere Fragen zu deiner Traumlehrstelle, möchtest du etwas Spezifisches über die
                    Brack.Alltron Berufsbildung wissen?
                </h5>
                <LayoutGrid desktop={3} tablet={2} mobile={1}>
                    <div>
                        <p>E-Mail</p>
                        <h6><a href="mailto:berufsbildung@brack.ch">berufsbildung@brack.ch</a></h6>
                    </div>
                    <div>
                        <p>Telefon</p>
                        <h6>+41 62 889 80 80</h6>
                    </div>
                    <div>
                        <p>Adresse</p>
                        <h6>Hintermättlistrasse 3, 5506 Mägenwil</h6>
                    </div>
                </LayoutGrid>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2700.081154812963!2d8.220947577427058!3d47.410358201507044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4790144833c196e7%3A0x944f26c57a234cc!2sHinterm%C3%A4ttlistrasse%203%2C%205506%20M%C3%A4genwil!5e0!3m2!1sde!2sch!4v1715678649923!5m2!1sde!2sch"
                    className="maps__iframe"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="brack-location-google-maps">
                </iframe>
            </LayoutSection>
        </div>
    )
}

export default ContactPage;
