import LayoutSection from "../../../components/base/layout/section/layout-section.component";
import {Color} from "../../../enums/color.enum";
import Table from "../../../components/base/table/table.component";
import AboutUsWay from "../../../components/complex/about-us/way/about-us-way.component";

const InfoPage = () => {
    const checkListCvHeader = [""];
    const checkListCvBody = [
        ["Berufs- und Firmenspezifisches Deckblatt"],
        ["Individuelles Bewerbungsschreiben (Firmenbezogen)"],
        ["Berufsspezifisches Motivationsschreiben"],
        ["Lückenloser Lebenslauf"],
        ["Alle Zeugnisse aus der Oberstufe"],
        ["Verlangter Eignungstest"],
        ["Freiwillige Eignungstests (Stellwerk, Check S2, Check S3...)"],
        ["Schnupperberichte oder Schnupperbestätigungen"],
        ["Sonstige Zertifikate oder Diplome"]
    ]

    const schnupperHeader = [""];
    const schnupperBody = [
        ["Frühzeitig am Treffpunk erscheinen (mind. 5 Min. früher)"],
        ["Schreibzeug und Block / Heft mitnehmen"],
        ["3-5 Fragen vorbereiten"],
        ["Eine Zwischenverpflegung"],
        ["Tel. Nummer der Kontaktperson der Firma ist bekannt"],
        ["Handy beim Schnuppern auf lautlos gestellt"],
    ]

    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Infos zu den Ausbildungs&shy;möglichkeiten</h1>
                <h2>Eine Berufslehre oder eine weiterführende Schule?</h2>
                <p>
                    Viele Schüler stehen plötzlich vor der Entscheidung: "Soll ich nun eine Lehre oder doch eine
                    weiterführende Schule besuchen? Welcher dieser beiden Wege, sind schlussendlich nachhaltiger?"
                    <br/>
                    Bei einer Berufslehre hat man die Option eine Berufsmaturität während oder anschliessend zu
                    absolvieren, mit der man später eine Fachhochschule oder über die Passerelle eine Universität
                    besuchen kann.
                    <br/>
                    Mit dem durchlässigen Schulsystem "kein Anschluss ohne Abschluss" kann man auch später eine höhere
                    karrierebildende Qualifikation auf dem 2ten Bildungsweg erwerben.
                </p>
                <h2>Weg zur Lehrstelle</h2>
                <p>
                    Der Weg zu einer geeigneten Lehrstelle ist nicht immer ganz einfach, klar und kann von Betrieb zu
                    Betrieb etwas variieren. Bei uns sieht dieser Weg wie gefolgt aus
                </p>
                <AboutUsWay/>
            </LayoutSection>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Schnuppern</h2>
                <p>
                    Eine Schnupperlehre ist eine sehr gute Möglichkeit, einen Beruf und eine Unternehmung in der Praxis
                    kennenzulernen und zu beschnuppern. Welche Schnupperarten gibt es und welche explizit bei Brack.Alltron?
                </p>
                <h3>Schnupperbesuche und Infoveranstaltungen für die Berufswahl</h3>
                <p>
                    Einzelne Arbeiten können ausgeführt werden. Der Schnupperbesuch gewährt einen ersten groben und
                    konkreten Einblick in den Arbeitsalltag eines Betriebs.
                </p>
                <h3>Schnupperlehre für die Berufswahl</h3>
                <p>
                    Jugendliche arbeiten aktiv in einem Lehrbetrieb mit. Grössere Betriebe bieten spezielle
                    Schnupperlehrprogramme für die einzelnen Berufe an. Die Schnupperlehre ermöglicht damit einen
                    vertieften Einblick in die wichtigsten Tätigkeiten eines Berufs.
                </p>
                <h3>Schnupperlehre für die Lehrstellebewerbung</h3>
                <p>
                    Die Bewerbungs-Schnupperlehre wird im Rahmen des Bewerbungsverfahrens für eine Lehrstelle
                    eingesetzt. Der Lehrbetrieb lädt die Jugendlichen für eine Schnupperlehre ein und prüft, ob sie sich
                    für diesen Beruf und diese Lehrstelle eignen.
                </p>
                <p>
                    Bei Brack.Alltron bieten wir jeden Monat einen offiziellen Berufsinfotag für maximal 12 interessierte
                    Jugendliche an. An dieser Veranstaltung lernen die Schüler*innen unsere Firma und die verschiedenen
                    Berufe in unsere Unternehmung besser kennen. Explizit können sie sich an diesem Tag für einen
                    bevorzugten Beruf unter schnuppy.ch anmelden.
                </p>
                <p>
                    Im Bewerbungsverfahren (Lehrstellenbewerbung) ist vor einer Lehrvertragsunterzeichnung ein
                    1–2-tägiger Schnuppertag in unserer Firma ein Wunsch/Pflicht.
                </p>
            </LayoutSection>
            <LayoutSection color={Color.BASE}>
                <h1>Checkliste Schnuppern:</h1>
                <Table header={schnupperHeader}
                       items={schnupperBody}/>
            </LayoutSection>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Inhalt des Bewerbungsdossiers</h2>
                <h5>Deckblatt</h5>
                <p>
                    Das Titel- oder Deckblatt bildet den ersten Eindruck deiner Bewerbung. Es soll auf dich aufmerksam
                    machen und dich von der Masse an Bewerbern etwas abheben. Das Deckblatt sollte etwas mit deinem
                    beworbenen Berufsbild oder der beworbenen Firma zu tun haben. Gleichzeitig sollte es mit deiner
                    gesuchten Lehrstelle und deinem Vornamen und Namen versehen werden.
                </p>
                <h5>Bewerbungsschreiben</h5>
                <p>
                    Das Bewerbungsschreiben ist das Begleitschreiben deiner Bewerbung und entscheidet in vielen Fällen,
                    ob deine Bewerbung weiter betrachtet wird oder nicht. Kurz prägnante Briefe haben eine höhere
                    Chance, als lange Romane. Am besten gliederst du das Bewerbungsschreiben in 7 Teile: 1.
                    Absenderadresse (Bewerber) 2. Empfängeradresse mit der zuständigen Kontaktperson (Firma) 3. Ort und
                    Datum der Erstellung 4. Titel- oder Überschrift mit der richtigen Bezeichnung der Lehrstelle und das
                    Lehrbeginn-Jahr stehen 5. Im Inhalt schreibst du, woher du von dieser offenen Lehrstelle weisst,
                    warum du dich genau für diese Lehrstelle in dieser Firma bewirbst und deine Bereitschaft für ein
                    persönliches Gespräch muss ebenfalls enthalten sein 6. Ein nettes Grusswort mit deinem Namen
                    und/oder deiner Unterschrift 7. Zuletzt die Beilagen/Dokumente erwähnen.
                </p>
                <h5>Motivationsschreiben</h5>
                <p>
                    Im Motivationsschreiben schreibst du in Aufsatzform welche Qualifikationen, Leidenschaften und
                    Motivationen du für diesen beworbenen Beruf mitbringst. Warum soll dir die Firma diese Lehrstelle
                    geben? Du musst den Lehrbetrieb von deinen Kompetenzen und von deiner Persönlichkeit überzeugen. Du
                    schreibst konkret, was du schon kannst und weisst oder kennst. Mit dem AIDA Modell kannst du das
                    Motivationsschreiben gut gestalten.
                </p>
                <p>
                    <b>Attention</b>:
                    Du machst auf dich aufmerksam (z.B. mit einem kreativen Titel oder Einstieg).
                    <br/>
                    <b>Interest</b>:
                    Du weckst das Interesse der Firma, dich näher kennenzulernen.
                    <br/>
                    <b>Desire</b>:
                    Du weckst den Wunsch, dich zum Vorstellungsgespräch einzuladen.
                    <br/>
                    <b>Action</b>:
                    Du forderst die Firma auf, dich zu kontaktieren.
                </p>
                <h5>Der Lebenslauf</h5>
                <p>
                    Der Lebenslauf zeigt deinen Lebens- und Werdegang in groben Zügen an (Familie, Schule, Bildung,
                    Kenntnisse, Hobbys usw.). Darin sollten folgende Informationen enthalten sein:
                </p>
                <ul>
                    <li>Überschrift/Titel</li>
                    <li>Personalien (Name, Adresse, Telefonnummer, E-Mail...)</li>
                    <li>Aktuelles Bewerbungsfoto</li>
                    <li>Familie (Name, Berufung, …)</li>
                    <li>Schulbildung (Welche Schulen hast du über die Jahre besucht)</li>
                    <li>Sprachkenntnisse</li>
                    <li>Weitere Kenntnisse</li>
                    <li>Hobbys/Freizeit</li>
                    <li>Referenzen</li>
                </ul>
                <p>Der Lebenslauf darf keine Lücken aufweisen (jedes Jahr ersichtlich/nachvollziehbar).</p>
                <h5>Alle Oberstufenzeugnisse</h5>
                <p>
                    Es müssen alle Oberstufenzeugnisse inkl. den Kompetenznachweise beigelegt werden (Verlauf der
                    Leistungen pro Semester)
                </p>
                <h5>Eignungstest</h5>
                <p>
                    Ein Eignungstest prüft neben den wichtigen Schulfächern für diesen Beruf auch weitere
                    Fähigkeiten/Kompetenzen wie z.B. Logik, Konzentration, Kurzzeitgedächtnis, usw. Es gibt verschiedene
                    Eignungstest, am besten fragst du beim Lehrbetrieb nach, welcher gewünscht ist.
                </p>
                <h5>Weitere Dokumente</h5>
                <p>
                    Falls du bereits Kurse, Seminare oder Zertifikate hast, kannst du diese auch gut zu deiner Bewerbung
                    beilegen. Berichte von Schnupperlehren sollten auch bei einer guten Bewerbung dabei sein.
                </p>
                <p>Falls du ungenügende- oder keine Leistungsnachweise wie Noten, Kompetenzen, Absenzen,
                    Schnupperberichte usw. in deinen Bewerbungsunterlagen hast, ist ein Richtigstellung / Erklärung oder
                    gar ein Empfehlungsschreiben einer Lehrperson empfehlenswert.
                </p>
                <p>
                    Mit einer klassischen 0-8-15 Bewerbung hast du heutzutage nur noch eine sehr geringe Change deine
                    Traumlehrstelle zu erhalten. Eine gute, saubere und komplette Bewerbung sollte heute aus mindestens
                    zwölf A4 Seiten bestehen.
                </p>
            </LayoutSection>
            <LayoutSection color={Color.BASE}>
                <h1>Checkliste Bewerbungs&shy;dossier:</h1>
                <Table header={checkListCvHeader}
                       items={checkListCvBody}/>
            </LayoutSection>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Bewerbungsgespräch</h2>
                <p>
                    Das Bewerbungsgespräch ist der erst persönliche Eindruck von dir. Du musst auf seriöse,
                    branchenübliche Kleidung und auf eine gepflegte Frisur achten. Informiere dich im Voraus über den
                    Weg, und rechne etwas mehr Zeit ein, damit du sicherlich pünktlich (5 Min. früher) vor Ort bist.
                    Vorgängig solltest du dir überlegen, was du auf folgende Fragen antworten wirst
                </p>
                <ul>
                    <li>Warum möchtest du genau diesen Beruf in unserer Unternehmung erlernen?</li>
                    <li>Was weisst du bereits über den Beruf?</li>
                    <li>Wieso bewirbst du dich gerade bei uns?</li>
                    <li>Was kannst du mir über unsere Firma alles sagen (Gründung, Umsatz, Mitarbeiteranzahl...)?</li>
                </ul>
            </LayoutSection>
        </>
    )
}

export default InfoPage;
