import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {Color} from "../../enums/color.enum";
import {Event} from "../../enums/event.enum";
import EventOverview from "../../components/complex/event/overview/event-overview.component";

const EventsPage = () => {
    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Veranstaltungen</h1>
                <h5>
                    Brack.Alltron engagiert sich seit Jahren für Jugendliche (Schüler*innen) und auch Erwachsenen
                    (Lehrpersonen), ihnen unsere verschiedenen Ausbildungsberufe etwas näher zu bringen.
                </h5>
                <EventOverview profession={null} start={0} size={8} eventType={Event.INTERNAL}/>
                <p>
                    Nebst den eigens organisierten "internen" Bildungsveranstaltungen, organisieren und wirken wir auch
                    bei externen Bildungs- Anlässen und Veranstaltungen (Schulprojekte, Kontaktveranstaltungen,
                    Ausstellungen und Messen) sehr erfolgreich mit.
                </p>
                <ul>
                    <li>Organisation und Mitarbeit von verschiedenen Info- und Schnupperanlässen</li>
                    <li>Teilnahme an verschiedenen kantonalen Berufsmessen</li>
                    <li>Teilnahme an Tischmessen an Oberstufenschulen</li>
                    <li>Organisation und Mithilfe an Schulprojekten</li>
                    <li>Teilnahme an verschiedenen Kontaktveranstaltungen an Handels- und Hochschulen</li>
                    <li>Referate über die schweizerische Bildungslandschaft und unsere eigene Berufsbildung</li>
                    <li>Und vieles mehr</li>
                </ul>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Externe Veranstaltungen</h1>
                <EventOverview profession={null} start={0} size={8} eventType={Event.EXTERNAL}/>
            </LayoutSection>
        </>
    )
}

export default EventsPage;
