import {Color} from "../../../enums/color.enum";
import LayoutSection from "../../../components/base/layout/section/layout-section.component";
import AboutUsRuedi from "../../../components/complex/about-us/ruedi/about-us-ruedi.component";
import BlogOverview from "../../../components/complex/blog/overview/blog-overview.component";
import {BlogTag} from "../../../enums/blog.enum";
import AboutUsHead from "../../../components/complex/about-us/head/about-us-head.component";
import LayoutGrid from "../../../components/base/layout/grid/layout-grid.component";
import imageRuedi from "../../../images/static/ruedi-geissmann.jpg";
import imageNoel from "../../../images/static/noel_guentert.jpg";
import imageMichael from "../../../images/static/michael_maier.jpg";
import imageNath from "../../../images/static/nathanael_houmard.png";
import Image from "../../../components/base/image/image.component";
import lernWerkstatt1 from "../../../images/lern-werkstatt/lern-werkstatt1.jpg";
import lernWerkstatt2 from "../../../images/lern-werkstatt/lern-werkstatt2.jpg";
import lernWerkstatt3 from "../../../images/lern-werkstatt/lern-werkstatt3.jpg";

const LernAcademyPage = () => {

    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>LernAcademy</h1>
                <h5>
                  Seit letztem Sommer treten wir mit unserer Berufsbildung auch als "LernAcademy" auf. Unter diesem Begriff steht unser komplettes
                  Bildungswesen mit den heute knapp 70 Lernenden an 3 verschiedenen Standorten. Das ehemalige Junior-Center und die IT-Academy
                  wurde in "LernWerkstatt" umbenannt. Unter diesem Begriff verstehen wir neben einem Team von 6-8 Lernenden aus den verschiedensten
                  Ausbildungsberufen auch unsere neuen Räumlichkeiten (praktische Spielwiese) im 1. OG des Gewerbeparkes Mägenwil.
                  Unsere Berufsinfotage, Schnuppertage oder auch unsere eigene Bildungsmesse werden in diesen tollen Räumen durchgeführt.
                  <br/>
                  Wir freuen uns auf euch!
                </h5>

                <h2>Unsere Berufsbildung</h2>
                <b>Eine Ausbildung bei Brack.Alltron ist vielseitig, dynamisch und interessant:</b>
                <ul>
                    <li>
                        Der Lernende erhält nach dem Start seiner Ausbildung eine 1-jährige interne und/oder externe
                        Grundausbildung (Basisausbildung/Basislehrjahr)
                    </li>
                    <li>
                        Die Lernenden durchlaufen ab dem 2. Lehrjahr verschiedene Teams und Abteilungen und arbeiten
                        mit spannenden Fachleuten an Aufgaben, wiederkehrenden Tätigkeiten und einmaligen Projekten
                        zusammen
                    </li>
                    <li>
                        Der Lernende kann auf Wunsch unsere interne LernWerkstatt für das Tüfteln an Hard- und Software
                        benutzen
                    </li>
                </ul>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <AboutUsRuedi/>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Leitung</h2>
                <LayoutGrid desktop={3} tablet={2} mobile={1}>
                    <AboutUsHead name="Ruedi Geissmann" title='Leiter "LernAcademy"' img={imageRuedi}/>
                    <AboutUsHead name="Noël Güntert" title='Stv. Leiter "LernAcademy"' img={imageNoel}/>
                    <AboutUsHead name="Michael Maier" title='Unterstützung "LernAcademy"' img={imageMichael}/>
                </LayoutGrid>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Ausbildner</h2>
                <LayoutGrid desktop={1} tablet={1} mobile={1}>
                    <AboutUsHead name="Nathanael Houmard" title='Leiter Ausbildung "Logistiker EFZ"' img={imageNath}/>
                </LayoutGrid>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Lernwerkstatt</h2>
                <LayoutGrid desktop={3} tablet={2} mobile={1}>
                    <AboutUsHead name="Gentian Beqiraj" title="Lernender Applikationsentwickler"/>
                    <AboutUsHead name="Nikola Ilic" title="Lernender Applikationsentwickler"/>
                    <AboutUsHead name="Luana Meier" title="Lernende Mediamatikerin"/>
                    <AboutUsHead name="Janis Schori" title="Lernende Mediamatikerin"/>
                    <AboutUsHead name="Emma Wehrle" title="Lernende Mediamatikerin"/>
                    <AboutUsHead name="Amina Sakho" title="Lernende Kauffrau"/>
                    <AboutUsHead name="Jenny Baldinger" title="Lernende Kauffrau"/>
                </LayoutGrid>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <LayoutGrid desktop={3} tablet={3} mobile={1}>
                    <Image src={lernWerkstatt1} alt="LernWerkstatt" isClickable={true}/>
                    <Image src={lernWerkstatt2} alt="LernWerkstatt" isClickable={true}/>
                    <Image src={lernWerkstatt3} alt="LernWerkstatt" isClickable={true}/>
                </LayoutGrid>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Lehrlingsprojekte</h2>
                <p>
                    Im Verlauf der Ausbildung arbeiten unsere Lernenden an verschiedenen interessanten Projekten. Hier
                    stellen wir dir einige vor.
                </p>
                <BlogOverview tag={BlogTag.PROJECTS} start={0} size={3}/>
            </LayoutSection>
        </>
    )
}

export default LernAcademyPage;
