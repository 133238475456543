import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {Color} from "../../enums/color.enum";
import BlogOverview from "../../components/complex/blog/overview/blog-overview.component";
import Dropdown from "../../components/base/dropdown/dropdown.component";
import {useState} from "react";
import {DropdownOption} from "../../components/base/dropdown/dropdown.type";
import {BlogTag, translateBlogTag} from "../../enums/blog.enum";

const BlogsPage = () => {

  const [selectedTag, setSelectedTag] = useState<DropdownOption>({key: null, display: "Kategorien"})

  const tagOptions: Array<DropdownOption> = [
    {key: null, display: "Alle"},
    {key: BlogTag.PROJECTS, display: translateBlogTag(BlogTag.PROJECTS)},
    {key: BlogTag.AWARDS, display: translateBlogTag(BlogTag.AWARDS)},
    {key: BlogTag.FIRST_STEPS, display: translateBlogTag(BlogTag.FIRST_STEPS)},
    {key: BlogTag.TRIAL_APPRENTICESHIP, display: translateBlogTag(BlogTag.TRIAL_APPRENTICESHIP)},
    {key: BlogTag.EXHIBITION, display: translateBlogTag(BlogTag.EXHIBITION)},
    {key: BlogTag.TRIPS, display: translateBlogTag(BlogTag.TRIPS)},
    {key: BlogTag.GRADUATION, display: translateBlogTag(BlogTag.GRADUATION)}
  ]

  return (
      <>
        <LayoutSection color={Color.BASE_INVERTED}>
          <h1>News</h1>
          <h5>Im Blog von Brack.Alltron Berufsbildung findest du Neuigkeiten und Informationen über unsere interne
            Berufsbildung.</h5>
          <Dropdown options={tagOptions} value={selectedTag} updateValue={setSelectedTag}/>
          <BlogOverview tag={selectedTag.key} start={0} size={9}/>
        </LayoutSection>
      </>
  )
}

export default BlogsPage;
