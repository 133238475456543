import {ButtonType} from "../../../components/base/button/button.type";
import Button from "../../../components/base/button/button.component";
import LayoutSection from "../../../components/base/layout/section/layout-section.component";
import {Color} from "../../../enums/color.enum";
import "./apply.style.scss";
import LayoutSequence from "../../../components/base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../../components/base/layout/sequence/layout-sequence.type";
import {navigateExternal} from "../../../services/navigation.service";

const ApplyPage = () => {

    return (
        <>
           <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Online-Ausschreibungen</h1>
                <iframe
                    src="https://jobs.brack.ch/?filter_50=1404146"
                    className="apply__iframe"
                    title="jobs.brack.ch">
                </iframe>
               <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                   <Button
                       color={Color.PRIMARY}
                       type={ButtonType.FILLED}
                       action={() => navigateExternal("https://jobs.brack.ch/?filter_50=1404146", true)}>
                       Bewerben auf Brack.Alltron
                   </Button>
               </LayoutSequence>
            </LayoutSection>
        </>
    )
}

export default ApplyPage;
