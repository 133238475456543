import {LayoutSummaryElement} from "../components/base/layout/summary/layout-summary.type";

export const overviewCompetec: Array<LayoutSummaryElement> = [
  {
    title: "Standorte",
    children: (
        <>
          <p>Wir sind an</p>
          <h1>8</h1>
          <p>Standorten vertreten</p>
        </>
    )
  },
  {
    title: "Ausbildungsberufe",
    children: (
        <>
          <p>Wir bieten</p>
          <h1>11</h1>
          <p>verschiedene Ausbildungsberufe an</p>
        </>
    )
  },
  {
    title: "Lernende",
    children: (
        <>
          <p>Wir bilden aktuell</p>
          <h1>69</h1>
          <p>Lernende aus</p>
        </>
    )
  },
  {
    title: "Mitarbeiter",
    children: (
        <>

          <p>Wir sind über</p>
          <h1>1'300</h1>
          <p>Mitarbeiter gross</p>
        </>
    )
  },
  {
    title: "Produkte",
    children: (
        <>
          <p>Wir verkaufen über</p>
          <h1>250'000</h1>
          <p>Produkte</p>
        </>
    )
  },
  {
    title: "Lieferungen",
    children: (
        <>
          <p>Wir haben 2024</p>
          <h1>4,36 Mio.</h1>
          <p>Pakete geliefert</p>
        </>
    )
  },
  {
    title: "Umsatz",
    children: (
        <>
          <p>Wir haben 2024</p>
          <h1>1,14 Mrd.</h1>
          <p>Umsatz erzielt</p>
        </>
    )
  }
]
