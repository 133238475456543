import "./about-us-ruedi.style.scss";
import Image from "../../../base/image/image.component";
import imageRuedi from "../../../../images/static/ruedi-geissmann.jpg";

const AboutUsRuedi = () => {

    return (
        <div className="about-us-ruedi">
            <div className="about-us-ruedi__head">
                <Image src={imageRuedi} alt="Ruedi Geissmann" isClickable={true}/>
                <div style={{textAlign: "center"}}>
                    <h4>Ruedi Geissmann</h4>
                    <p>Leiter Berufsbildung "LernAcademy"</p>
                </div>
            </div>
            <div className="about-us-ruedi__body">
                <h2>Interview mit dem Leiter "LernAcademy"</h2>
                <h6>Ausbildungsplätze mit Zukunft</h6>
                <p>
                    Jugendliche auszubilden ist eine reizvolle und nachhaltige Aufgabe.
                    <br/>
                    Tagtäglich stehen wir im Kontakt mit jungen Menschen und begleiten sie auf ihrem Bildungsweg.
                    Wir vermitteln Berufskenntnisse, Handgriffe und wichtige betriebliche Abläufe. Die didaktischen
                    Fähigkeiten als Ausbildner*in ist ausschlaggebend für den Lernerfolg unserer Jugend.
                    Auch im zwischenmenschlichen Bereich kommt uns eine wichtige Rolle zu.
                </p>
                <h6>Eine ausgewogene Work-Life-Balance</h6>
                <p>
                    Während der Ausbildung dürfen Familie, Freunde und Hobbys aber nicht zu kurz kommen.
                    Ein guter Ausgleich zwischen Beruf und Privatleben fördert die Motivation und macht auch den Kopf
                    frei. «Die gute
                    Mischung macht es aus».
                </p>
                <h6>Fehler dürfen passieren</h6>
                <p>
                    Fehler dürfen in einer Ausbildung passieren, denn aus Fehlern lernt man bekanntlich. Wichtig ist
                    hier
                    vor allem,
                    dass man den Fehler auch eingesteht und ihn nicht wiederholt. «Wenn Auszubildende lernen mit Fehlern
                    umzugehen,
                    stärkt das gleichzeitig auch ihr Selbstbewusstsein».
                    <br/>
                    Als erwachsene und erfahrene Bezugsperson helfen wir unseren Lernenden, den Übergang von der Schule
                    in den Beruf
                    zu bewältigen und sich erfolgreich in die neue Arbeitswelt zu integrieren.
                </p>
            </div>
        </div>
    )
}

export default AboutUsRuedi;
