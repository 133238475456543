import { useState, useEffect, useCallback, useRef } from "react";
import "./header.style.scss";
import logoBrackAlltron from "../../../images/logos/logo-brack-alltron.svg";
import Nav from "../nav/nav.component";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [visible, setVisible] = useState(true);
  const lastScrollY = useRef(0);

  const handleScroll = useCallback(() => {
    const drawer = document.querySelector(".drawer");
    if (drawer && drawer.classList.contains("drawer__active")) return;

    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY.current && currentScrollY > 50) {
      setVisible(false);
    } else {
      setVisible(true);
    }
    lastScrollY.current = currentScrollY;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
      <div className={`header__container ${visible ? "header--visible" : "header--hidden"}`}>
        <header className="header">
          <>
            <NavLink to="/" className="header__logo-link">
              <img className="header__logo" src={logoBrackAlltron} alt="Brack.Alltron Berufsbildung Logo" />
            </NavLink>
            <Nav />
          </>
        </header>
      </div>
  );
};

export default Header;
