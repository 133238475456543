import "./footer.style.scss";
import {NavLink} from "react-router-dom";
import FooterTile from "./tile/footer-tile.component";
import Image from "../../base/image/image.component";
import iconInstagram from "../../../images/icons/instagram_logo.png";
import iconFacebook from "../../../images/icons/facebook_logo.png";
import iconX from "../../../images/icons/x_logo_black.png";
import iconLinkedin from "../../../images/icons/linkedin_logo.png";
import iconSchool from "../../../images/icons/school_icon.png";
import iconNewspaper from "../../../images/icons/newspaper_icon.png";
import {useState} from "react";

const Footer = () => {
  const [instagramHover, setInstagramHover] = useState<boolean>(false)
  const [facebookHover, setFacebookHover] = useState<boolean>(false)
  const [xHover, setXHover] = useState<boolean>(false)
  const [linkedInHover, setLinkedInHover] = useState<boolean>(false)
  const [ausbildungenHover, setAusbildungenHover] = useState<boolean>(false)
  const [newsHover, setNewsHover] = useState<boolean>(false)

  return (
      <div className="footer__container">
        <footer className="footer">
          <div className="footer__grid">
            <a href="https://www.instagram.com/brackch/"
               target="_blank"
               rel="noreferrer">
              <FooterTile onMouseEnter={() => setInstagramHover(true)} onMouseLeave={() => setInstagramHover(false)}>
                <div className={"footer__grid-tile " + (instagramHover ? "hover" : "")}>
                  <Image src={iconInstagram} alt="Instagram"/>
                  <h4 className={"footer__grid-tile-body " + (instagramHover ? "hover" : "")}>Instagram</h4>
                </div>
              </FooterTile>
            </a>
            <a href="https://www.facebook.com/brack.ch/"
               target="_blank"
               rel="noreferrer">
              <FooterTile onMouseEnter={() => setFacebookHover(true)} onMouseLeave={() => setFacebookHover(false)}>
                <div className={"footer__grid-tile " + (facebookHover ? "hover" : "")}>
                  <Image src={iconFacebook} alt="Facebook"/>
                  <h4 className={"footer__grid-tile-body " + (facebookHover ? "hover" : "")}>Facebook</h4>
                </div>
              </FooterTile>
            </a>
            <a href="https://twitter.com/brack"
               target="_blank"
               rel="noreferrer">
              <FooterTile onMouseEnter={() => setXHover(true)} onMouseLeave={() => setXHover(false)}>
                <div className={"footer__grid-tile " + (xHover ? "hover" : "")}>
                  <Image src={iconX} alt="X"/>
                  <h4 className={"footer__grid-tile-body " + (xHover ? "hover" : "")}>X</h4>
                </div>
              </FooterTile>
            </a>
            <a href="https://ch.linkedin.com/company/brackch"
               target="_blank"
               rel="noreferrer">
              <FooterTile onMouseEnter={() => setLinkedInHover(true)} onMouseLeave={() => setLinkedInHover(false)}>
                <div className={"footer__grid-tile " + (linkedInHover ? "hover" : "")}>
                  <Image src={iconLinkedin} alt="LinkedIn"/>
                  <h4 className={"footer__grid-tile-body " + (linkedInHover ? "hover" : "")}>LinkedIn</h4>
                </div>
              </FooterTile>
            </a>
            <NavLink to="/ausbildungen">
              <FooterTile onMouseEnter={() => setAusbildungenHover(true)} onMouseLeave={() => setAusbildungenHover(false)}>
                <div className={"footer__grid-tile " + (ausbildungenHover ? "hover" : "")}>
                  <Image src={iconSchool} alt="Ausbildungen"/>
                  <h4 className={"footer__grid-tile-body " + (ausbildungenHover ? "hover" : "")}>Ausbildungen</h4>
                </div>
              </FooterTile>
            </NavLink>
            <NavLink to="/news">
              <FooterTile onMouseEnter={() => setNewsHover(true)} onMouseLeave={() => setNewsHover(false)}>
                <div className={"footer__grid-tile " + (newsHover ? "hover" : "")}>
                  <Image src={iconNewspaper} alt="News"/>
                  <h4 className={"footer__grid-tile-body " + (newsHover ? "hover" : "")}>News</h4>
                </div>
              </FooterTile>
            </NavLink>
          </div>
          <div className="footer__meta">
            <p>Betreut durch die <NavLink to="/ueber-uns/lernacademy">LernAcademy</NavLink></p>
            <p>Die Berufsbildung ist Teil von Brack.Alltron</p>
            <div className="footer__meta-links">
              <a href="https://www.brackalltron.ch/legal/datenschutz"
                 target="_blank"
                 rel="noreferrer">Datenschutz</a>
              <a href="https://www.brackalltron.ch/legal/impressum"
                 target="_blank"
                 rel="noreferrer">Impressum</a>
              <NavLink to="/ueber-uns/kontakt"
                       style={{"textDecoration": "none"}}>
                Kontakt
              </NavLink>
            </div>
          </div>
        </footer>
      </div>
  )
}

export default Footer;
