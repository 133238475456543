import "./drawer.style.scss";
import {DrawerProps} from "./drawer.type";
import {Color} from "../../../enums/color.enum";
import IconButton from "../../base/icon/button/icon-button.component";
import {Size} from "../../../enums/size.enum";

const Drawer = (props: DrawerProps) => {
  const closeDrawer = () => {
    props.updateSelectedPage();
    props.updateOpen();
  }

  return (
      <>
        <IconButton
            name="segment"
            color={Color.BASE_INVERTED}
            size={Size.M}
            action={props.updateOpen}/>
        <div className={`drawer ${props.open ? 'drawer__active' : 'drawer__inactive'}`}>
          <div className={`drawer__container ${props.color}`}>
            <div className="drawer__head">
              {
                  props.selectedPage?.subPages && (
                      <div className="back-button">
                        <IconButton
                            name="chevron_left"
                            color={props.color}
                            size={Size.M}
                            action={props.updateSelectedPage}/>
                      </div>
                  )
              }
              <div className="close-button">
                <IconButton
                    name="close"
                    color={props.color}
                    size={Size.M}
                    action={closeDrawer}/>
              </div>
            </div>
            <div className="drawer__body">
              {
                props.children
              }
            </div>
          </div>
        </div>
      </>
  )
}

export default Drawer;
