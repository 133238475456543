import "./about-us-competec.style.scss";
import Image from "../../../base/image/image.component";
import logosBrackAlltron from "../../../../images/logos/all_logos_white_bg.jpeg";
import LayoutSequence from "../../../base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../../base/layout/sequence/layout-sequence.type";
import Button from "../../../base/button/button.component";
import {Color} from "../../../../enums/color.enum";
import {ButtonType} from "../../../base/button/button.type";
import {navigateExternal} from "../../../../services/navigation.service";

const AboutUsCompetec = () => {

    return (
        <div className="about-us-competec">
            <Image src={logosBrackAlltron} alt="Logos Brack.Alltron"/>
            <h5>
              Aus der 1994 gegründete Brack Consulting wurde 2007 zusammen mit dem Handelsunternehmen Alltron AG die damalige Competec Holding gegründet.
              Die Competec-Gruppe wurde nun in Brack.Alltron umbenannt. Die neue Unternehmensgruppe hinter der die Marken Brack, Brack Business, Alltron, DayDeal und Jamei
              sowie dem Logistikdienstleister Brack.Alltron Logistik stehen, beschäftigt 1335 Mitarbeitende (Ende 2024) und hat 2024 einen Umsatz von 1.14 Milliarden Franken erzielt.
            </h5>
            <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                <Button
                    color={Color.PRIMARY}
                    type={ButtonType.OUTLINED}
                    action={() => navigateExternal("https://brackalltron.ch/%C3%BCber-uns", true)}>
                    Mehr erfahren
                </Button>
            </LayoutSequence>
        </div>
    )
}

export default AboutUsCompetec;
